import React, { Component } from "react";
import Popup from "./Popup";
import { PopupContext } from "../Context/PopupContext";
//solana kusama polkadot mina concordium centrifuge regen
//  the graph comming soon
class StakeWithUs extends Component {
  static contextType = PopupContext;

  render() {
    let mainNetworks = [];
    let testNetworks = [];
    if (this.props.data) {
      mainNetworks = this.props.data.networks.mainnet;
      testNetworks = this.props.data.networks.testnet;
    }
    return (
      <section id="stake-with-us">
        <Popup />
        <div className="stake-that-wrapper">
          <h2 className="stake-with-us-title">Stake with us</h2>
          <div className="underline-yellow-line" />

          <div className="stake-that-wrapper-mainnet">
            {mainNetworks &&
              mainNetworks.map((network, key) => (
                <div className="flip-card" key={key} data-aos="fade-left">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        className="crypto-logo"
                        src={network.image}
                        alt={network.name}
                      />
                      <h3 className={`crypto-name ${network.name}`}>{network.name}</h3>
                    </div>
                    <div className="flip-card-back">
                      <h3 className="crypto-name">{network.network}</h3>
                      {/* <h4>{network.abvr}</h4> */}
                      {/* <span>{network.reward}</span> */}
                      {network.comingSoon === false ? (
                        <span
                          className="stake-btn"
                          onClick={() => {
                            this.context.openPopup(network);
                          }}
                        >
                          STAKE
                        </span>
                      ) : (
                        <span>COMING SOON</span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    );
  }
}

export default StakeWithUs;
