import React, { Component } from 'react';

const UnorderedList = props => {
   return (<ul>
      {props.list.map((listItem, index) => {
         return (<li key={index}>{listItem.text}</li>)
      })}
   </ul>)
}

class About extends Component {
   render() {

      if (this.props.data) {
         var about = this.props.data.about;
         var aboutPic = this.props.data.aboutPic;
      }

      return (
         <section id="about">
            <div className="row-with-colums" data-aos="slide-up">
               <div className="about-left">
                  <img className="about-pic" src={aboutPic} alt="Stake that - about us" data-aos="zoom-in" />
                  <p>Also we are in the top 10 validators in the <span className="yellow">Kusama</span> <a href="https://thousand-validators.kusama.network/#/leaderboard" className='blue'>'1000 Validators' program</a>. 
                  We've been running validators on multiple mainnet and testnet networks, among which we can mention <span className="yellow">Cosmos, Iris, Terra, Kava, Solana, Near, Akash, Regen, Kusama, Polkadot, Centrifuge, Matic, NuCypher, Cooncordium, Mina.</span></p>
               </div>
               <div className="about-right">
                  <h2 className="about-us-h2">About Us</h2>
                  <div className="yellow-line" />
                  {about && about.map((item, key) => {
                     if (item.type === "text") {
                        return (<p key={key} dangerouslySetInnerHTML={{__html:item.text}} />)
                     } else {
                        return (<UnorderedList list={item.list} key={key} />)
                     }
                  })}
               </div>
            </div>
         </section>

      );
   }
}

export default About;
