import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const Header = props => {
   let description = null;
   if (props.data) {
      description = props.data.description;
   }

   const particlesInit = useCallback(async engine => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadFull(engine);
   }, []);

   const particlesLoaded = useCallback(async container => {
      await console.log(container);
   }, []);

   return (
      <header id="home">
         <img src="./images/logo.png" id="logo" height="100" />
         <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
            <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

            <ul id="nav" className="nav">
               <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
               <li><a className="smoothscroll" href="#about">About</a></li>
               <li><a className="smoothscroll" href="#tech">Tech</a></li>
               <li><a className="smoothscroll" href="#stake-with-us">Stake with us</a></li>
               <li><a className="smoothscroll" href="#the-team">The team</a></li>
               <li><a className="smoothscroll" href="#contact">Contact</a></li>
            </ul>
         </nav>

         <div className="row banner">
            {/* <Particles
               params={{
                  "particles": {
                     "number": {
                        "value": 25,
                        "density": {
                           "enable": true,
                           "value_area": 800
                        }
                     },
                     "line_linked": {
                        "enable": false
                     },
                     "move": {
                        "speed": 1,
                        "out_mode": "out"
                     },
                     "shape": {
                        "type": [
                           "image",
                        ],
                        "image": [
                           {
                              "src": "./images/kusama.png",
                              "height": 20,
                              "width": 23
                           },
                           {
                              "src": "./images/solana.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/polkadot.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/osmos.png",
                              "height": 20,
                              "width": 23
                           },
                           {
                              "src": "./images/solana.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/svv-modified.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/kusama.png",
                              "height": 20,
                              "width": 23
                           },
                           {
                              "src": "./images/solana.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/polkadot.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/centrifuge.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/mina.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/matic.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/juno.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/regen.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/velas.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/svv-modified.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/sora.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/concordium.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/osmos.png",
                              "height": 20,
                              "width": 23
                           },
                        ]
                     },
                     "color": {
                        "value": "#CCC"
                     },
                     "size": {
                        "value": 30,
                        "random": false,
                        "anim": {
                           "enable": true,
                           "speed": 4,
                           "size_min": 10,
                           "sync": false
                        }
                     }
                  },
                  "retina_detect": false,
                  "width": 1920,
                  "height": 720,
               }}
            /> */}

            <Particles
               id="tsparticles"
               init={particlesInit}
               loaded={particlesLoaded}
               options={{
                  background: {
                     color: {
                        value: "#29292D",
                     },
                  },
                  fpsLimit: 120,
                  particles: {
                     color: {
                        value: "#ffffff",
                     },
                     links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 0.5,
                     },
                     collisions: {
                        enable: false,
                     },
                     move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                           default: "bounce",
                        },
                        random: false,
                        speed: 1,
                        straight: false,
                     },
                     number: {
                        density: {
                           enable: true,
                           area: 800,
                        },
                        value: 40,
                     },
                     opacity: {
                        value: 1,
                     },
                     "shape": {
                        "type": [
                           "image",
                        ],
                        "image": [
                           {
                              "src": "./images/kusama.png",
                              "height": 20,
                              "width": 23
                           },
                           {
                              "src": "./images/solana.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/solana.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/centrifuge.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/mina.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/matic.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/juno.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/regen.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/velas.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/sora.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/concordium.png",
                              "height": 20,
                              "width": 20
                           },
                           {
                              "src": "./images/arable.png",
                              "height": 20,
                              "width": 20
                           }
                        ]
                     },
                     size: {
                        value: { min: 1, max: 30 },
                     },
                  },
                  detectRetina: true,
                  width: 1920,
                  height: 720,
               }}
            />
            <div className="banner-text">
               <h1 className="responsive-headline">{description}</h1>
            </div>
         </div>
         <a className="smoothscroll stake-with-us-btn" href="#stake-with-us">Stake with us</a>
         <p className="scrolldown">
            <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
         </p>

      </header>
   );
}

export default Header;
