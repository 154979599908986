import React, { Component } from "react";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import StakeWithUs from "./Components/StakeWithUs";
import Team from "./Components/Team";
import Tech from "./Components/Tech";

import AOS from "aos";
import "aos/dist/aos.css";

import PopupContextProvider from "./Context/PopupContext";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      data: {},
    };
    AOS.init();
  }

  getData() {
    $.ajax({
      url: "./data.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ data: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <div className="App">
        <PopupContextProvider>
          <Header data={this.state.data.main} />
          <About data={this.state.data.main} />
          <Tech data={this.state.data.main} />
          <StakeWithUs data={this.state.data.main} />
          <Team data={this.state.data.main} />
          <Footer data={this.state.data.main} />
        </PopupContextProvider>
      </div>
    );
  }
}

export default App;
