import React, { Component } from "react";

class Footer extends Component {
  render() {
    if (this.props.data) {
      var networks = this.props.data.social.map(function (network) {
        return (
          <li key={network.name}>
            <a href={network.url}>
              <i className={network.className}></i>
            </a>
          </li>
        );
      });
    }

    return (
      <footer id="contact">
        <div className="row">
          <div className="twelve columns">
            <div className="widget widget_contact">
              <h4>Contact</h4>
              <p className="address">
                <a href="mailto:stakethathq@gmail.com">
                  <img
                    className="contact-icon"
                    src="./images/contact.png"
                    alt="contact stake that"
                  />
                </a>
                {/* <a href="https://twitter.com/stake_that" target="_blank">
                  <img
                    className="twitter-icon"
                    src="./images/twitter.png"
                    alt="twitter stake that"
                  />
                </a> */}
              </p>
            </div>
            {/* <ul className="social-links">
              {networks}
            </ul> */}

            <ul className="copyright">
              <li>&copy; Copyright 2021 Stake That</li>
              <li>
                Design by{" "}
                <a title="Styleshout" href="https://bitsmiths.co/">
                  BitSmiths
                </a>
              </li>
            </ul>
          </div>
          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
