import React, { createContext, Component } from "react";

export const PopupContext = createContext();

class PopupContextProvider extends Component {
  state = {
    showPopup: false,
    network: {},
  };

  openPopup = (network) => {
    this.setState({
      showPopup: true,
      network,
    });
  };

  closePopup = () => {
    this.setState({
      showPopup: false,
      network: {},
    });
  };

  render() {
    return (
      <PopupContext.Provider
        value={{
          ...this.state,
          openPopup: this.openPopup,
          closePopup: this.closePopup,
        }}
      >
        {this.props.children}
      </PopupContext.Provider>
    );
  }
}

export default PopupContextProvider;
