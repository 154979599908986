import React, { Component } from "react";
import { PopupContext } from "../Context/PopupContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QRCode from "qrcode.react";

class Popup extends Component {
  static contextType = PopupContext;

  render() {
    const { showPopup } = this.context;

    return (
      <>
        <ToastContainer />
        {showPopup && (
          <div className="popup-wraper">
            <div className="popup-container">
              <div className="popup-content">
                <div className="popup-left">
                  <span className="popup-title">
                    {this.context.network.name}
                  </span>
                  <QRCode value={this.context.network.address} />
                </div>
                <div className="popup-right">
                  {this.context.network.url !== "" && (
                    <p style={{ padding: 0 }}>
                      <a href={this.context.network.url} target="_blank">
                        {this.context.network.linkName}
                      </a>
                    </p>
                  )}
                  <span className="delegate-to">Address:</span>
                  <input
                    type="text"
                    id="address-input"
                    className="delegate-input"
                    defaultValue={this.context.network.address}
                    placeholder={this.context.network.address}
                  />
                  <span
                    className="copy"
                    style={{
                      top: `${
                        this.context.network.url !== "" ? "70px" : "40px"
                      }`,
                    }}
                    onClick={() => {
                      const copyText = document.getElementById("address-input");
                      copyText.select();
                      copyText.setSelectionRange(0, 99999);
                      document.execCommand("copy");
                      toast.dark("Copied to clipboard!");
                    }}
                  />
                </div>
              </div>
              {/* <span className="delegate-text">Lorem ipsum dolor sit amet berzeluis compredes</span> */}
              <button
                className="close-popup"
                onClick={() => this.context.closePopup()}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Popup;
