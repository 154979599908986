import React, { Component } from 'react';

const UnorderedList = props => {
    return (<ul>
        {props.list.map((listItem, index) => {
            return (<li key={index}>{listItem.text}</li>)
        })}
    </ul>)
}

class Tech extends Component {
    render() {

        if (this.props.data) {
            var tech = this.props.data.tech;
            var techPic = this.props.data.techPic;
        }

        return (
            <section id="tech">
                <div className="row-with-colums" data-aos="slide-up">
                    <div className="tech-left">
                        <h2 className="tech-us-h2">Technology</h2>
                        <div className="tech-yellow-line" />
                        {tech && tech.map((item, key) => {
                            if (item.type === "text") {
                                return (<p key={key} dangerouslySetInnerHTML={{__html:item.text}} />)
                            } else {
                                return (<UnorderedList list={item.list} key={key} />)
                            }
                        })}
                    </div>
                    <div className="tech-right">
                        <img className="tech-pic" src={techPic} alt="Stake that - tech" data-aos="zoom-in" />
                    </div>
                </div>
            </section>

        );
    }
}

export default Tech;
