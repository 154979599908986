import React, { Component } from "react";

class Team extends Component {
    constructor(props) {
        super(props)

        this.state = {
            members: [
                {
                  "name": "Andrei",
                  "position": "Software engineer/Devops",
                  "image": "./images/andrei_sid.jpg",
                  "imageHover": "./images/sid_accesories.png",
                  "img": "",
                  "animation": "fade-left",
                  "social": [
                    {
                      "icon": "./images/linkedin.png",
                      "url": "https://www.linkedin.com/in/andreisid/",
                      "class": "linkedin"
                    },
                    {
                      "icon": "./images/github.png",
                      "url": "https://github.com/andreisid",
                      "class": "github"
                    }
                  ]
                },
                {
                  "name": "Cristi",
                  "position": "Software engineer",
                  "image": "./images/cristi.jpg",
                  "imageHover": "./images/cristi_accesories.png",
                  "animation": "fade-down",
                  "social": [
                    {
                      "icon": "./images/linkedin.png",
                      "url": "https://www.linkedin.com/in/calacianu-cristi-03ab0a25/",
                      "class": "linkedin"
                    }
                  ]
                },
                {
                  "name": "Mihai",
                  "position": "Software engineer",
                  "image": "./images/mihai_marca.png",
                  "imageHover": "./images/mihai_accessories.png",
                  "animation": "fade-up",
                  "social": [
                    {
                      "icon": "./images/linkedin.png",
                      "url": "https://www.linkedin.com/in/mihai-marca-71974417b/",
                      "class": "linkedin"
                    }
                  ]
                },
                {
                  "name": "Radu",
                  "position": "Design & marketing",
                  "image": "./images/radu.png",
                  "imageHover": "./images/radu_accesories.png",
                  "animation": "fade-right",
                  "social": [
                    {
                      "icon": "./images/linkedin.png",
                      "url": "https://www.linkedin.com/in/radust-design",
                      "class": "linkedin"
                    },
                    {
                      "icon": "./images/insta.png",
                      "url": "https://www.instagram.com/radust.design/",
                      "class": "instagram"
                    },
                    {
                      "icon": "./images/fb.png",
                      "url": "https://www.facebook.com/radust.design",
                      "class": "facebook"
                    }
                  ]
                },
                {
                  "name": "Edy",
                  "position": "Full stack Mascot",
                  "image": "./images/edy.png",
                  "imageHover": "./images/edy_accesories.png",
                  "animation": "fade-up",
                  "social": []
                }
              ]
        }
    }

    render() {
        return (
            <section id="the-team">
                <h2 className="meet-the-team">Meet the team</h2>
                <div className="team-underline-yellow-line" />
                <div className="team-container">
                    {this.state.members && this.state.members.map((member, key) => (
                        <div className="team-member" key={key} data-aos={member.animation}>
                            <img
                                className="member-image"
                                src={member.img || member.image}
                                alt={member.name}
                                onMouseEnter={() => {
                                    const members = this.state.members;
                                    members[key].img = member.imageHover;
                                    this.setState({
                                        members
                                    });
                                }}
                                onMouseLeave={() => {
                                    const members = this.state.members;
                                    members[key].img = member.image;
                                    this.setState({
                                        members
                                    });
                                }}
                            />
                            <h4 className="team-member-name">{member.name}</h4>
                            <p className="position">{member.position}</p>
                            <div className="social-link">
                                {member.social.map((network, index) => {
                                    return (<a href={network.url} className="social-icon" key={index} target="_blank">
                                        <img height="30" src={network.icon} />
                                    </a>)
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        )
    }
}

export default Team;